(function() {
    'use strict';

    // ? VARIABLES 
    var cerrado     = true;
    var menuLat     = false;
    var resEnPantalla = 0;

    let promociones = [];
    let heros = [];    

    window.onload = (event) => {
        iniciarClicks();

        // ! CÓDIGO PARA VISUALIZAR LAS RESEÑAS DE VIDEO 
        const vid_resenas = document.getElementById('cntTestimonios');
        
        if (vid_resenas !== null) {
            
            video_testimonios();
             
        }

        // ! CÓDIGO PARA VISUALIZAR TODOS LOS VÍDEOS INFORMATIVOS DE LOS SISTEMAS
        const menus_informativos = document.getElementById('menus_informativos');

        if (menus_informativos !== null) {

            menus_informativos.innerHTML = '';

            fetch('../../assets/videos_pagina.json')
            .then(response => response.json())
            .then(data => {

                let videos_sistema = data.videos.filter(video => video.tipo === menus_informativos.getAttribute("sistema"))

                let i = 0;
                videos_sistema.forEach(elem => {
                    const divVideo = document.createElement('div')
                    divVideo.classList.add('lista_elemento')
                    divVideo.innerHTML = `
                        <i class="fab fa-youtube color_rojo parpadeo urlVideo" idvid="${elem.idvid}" tipo="${elem.tipo}"></i><a idvid="${elem.idvid}" tipo="${elem.tipo}" class="urlVideo">${elem.titulo}</a>
                    `;
                    menus_informativos.appendChild(divVideo);

                    if ( i === 0 ) {
                        const portada_video = document.getElementById('portada_video');
                        portada_video.innerHTML = `
                            <picture>
                                <source srcset="build/img/vportadas/${elem.idvid}.avif" type="image/avif">
                                <source srcset="build/img/vportadas/${elem.idvid}.webp" type="image/webp">
                                <img width="350" height="150" src="${elem.idvid}.jpg" alt="Portada del vídeo en pantalla">
                            </picture>

                            <a href="#myModal" class="play urlVideo"><img width="100" height="100" src="build/img/play.png" alt="Dale PLAY al vídeo" class="urlVideo" idvid="${elem.idvid}" tipo="${elem.tipo}"></a>
                        `;
                        i++;
                    }
                    
                })
    
            })
            .catch(error => {
                console.log('Error al cargar el archivo JSON:', error);
            });                      
        }

        //! REPRPDUCCIÓN DE VIDEO 
        var $vtnaVideo = $('#ventanaVideo');
        var $iframeVideo = $('#iframeVideo');

        if ($vtnaVideo !== null) {

            $vtnaVideo.on('hidden.bs.modal', function (event) {
                $iframeVideo.attr('src',null);
            });
        }

        // ! CÓDIGO PARA AGREGAR EL EQUIPO DE CÓMPUTO
        const eq_container = document.getElementById('equipo-container');

        if (eq_container !== null) {
            
            eq_container.innerHTML = '';

            fetch('../../assets/equipocomputo.json')
                .then(response => response.json())
                .then(data => {
                    
                    const productos = data.productos;

                    productos.forEach(producto => {

                        if (producto.activo == "true") {
                            const tarjeta = document.createElement('div');
                            tarjeta.classList.add('box');

                            // Agrega el contenido de la tarjeta informativa (nombre, descripción, etc.)
                            tarjeta.innerHTML = `

                                <div class="box_image">
                                    <img src="${producto.foto}" class="img-fluid rounded-top" alt="${producto.nombre}">
                                </div>

                                <div class="box_content"> 
                                    <div class="box_texto">
                                        <a href="" class="text-dark"><h5>${producto.nombre}</h5></a>
                                        <p class="text-muted"><i class="fas fa-check-circle"></i> ${producto.descripcion}</p>
                                        <p class="text-muted"><i class="fas fa-check-circle"></i> ${producto.promocion}</p>
                                    </div>
                                    <div class="box_footer">
                                        <span>$${producto.precio} mx</span>
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#myModal" id="masinfo" idprod="${producto.clave}">
                                            Más Detalles
                                        </button>                                    
                                    </div>
                                </div>
                            `;

                            // Agrega la tarjeta al contenedor de productos
                            eq_container.appendChild(tarjeta);
                        }
                    });

                })
            .catch(error => {
                console.error('Error al obtener los datos del archivo JSON:', error);
            });                      
            
        };

        // ! CÓDIGO PARA VISUALIZAR 1 RESEÑA EN LAS PÁGINAS DE LOS SISTEMAS
        // ! ESTE CÓDIGO SOLO MOSTRAMOS 1 RESEÑA A LA VEZ

        const pag_software = document.getElementById('conjunto_testimonios');

        if (pag_software !== null) {

            obtenTestimonios();
        }

        //! LLENAR LOS BANNERS DE PUBLICIDAD
        const div_pub = document.getElementById('div_pub');

        if (div_pub !== null) {
            llena_bannerspub(div_pub);             
        };

    };

    function iniciarClicks() {
            
        document.addEventListener('click', (e) => {
            e.preventDefault();
            clicksWeb(e.target);
        });

        window.addEventListener('scroll', function() {
            navBackground();
        })
        navBackground();

        if (document.getElementById("banners_equipo")) {
            promociones = document.querySelectorAll('.paquete_hardware')            
            setInterval(next_promo, 7000); 
        }

        if (document.getElementById("fondosHero")) {
            heros = document.querySelectorAll('.fondo_hero');
            setInterval(next_fondo, 3000); 
        }

        // Verificando si el icono de EN VIVO debe de estar visible

        if (document.getElementById("enVivo")) {

            activaTrasmision();
        
        }

    }

    function activaTrasmision() { 

        fetch('https://www.apiweb.servidorbdk.com/tbtransmision?select=activa,linksrc')
        .then(response => response.json())
        .then(data => {
            const info = data.results[0]; // Asumiendo que siempre hay al menos un resultado
            const enVivo = document.getElementById('enVivo');
            const videoIframe = document.querySelector('.video_envivo iframe');

            if (info.activa === "1") {
                enVivo.style.display = 'block';
                videoIframe.src = info.linksrc; // Actualiza el enlace del iframe
            } else {
                enVivo.style.display = 'none';
            }
        })
        .catch(error => {
            console.error('Error al cargar la información de la transmisión:', error);
            document.getElementById('enVivo').style.display = 'none'; // Ocultar en caso de error
        });
        
    }

    function rotarCard(elem, faceCard) {
        
        let card = {};
        card = elem.parentNode.parentNode.parentNode.parentNode.childNodes;

        // console.log(card);
        
        const front = card[1];
        const back = card[3];
        
        front.classList.toggle('rotar');
        back.classList.toggle('rotar');
        back.classList.toggle('rotar360');
    }

    // ! FUNCIÓN PARA ACTIVAR EL POPUP PARA EL AVISO DE LAS TRANSMISIONES
    function toggleStriming() {
        const popStriming = document.querySelector('.popupStriming');

        popStriming.classList.toggle('active');
    }    


    // ! FUNCIÓN PARA ACTIVAR TODOS LOS CLICS DE LA PÁGINA
    function clicksWeb(objetoDom) {

        console.log(objetoDom);

        // ? PARA REPRODUCIR UN VÍDEO DE LOS DEL ROBOT, DONDE CREA UN IFRAME DIRECTO EN EL HTML
        // ? SIN USAR UNA VENTANA MODAL
        if ( objetoDom.matches('.urlVideoRobot') ) {
        
            video_robot(objetoDom);
            
        }


        // ? BOTON PARA AGREGAR LOS DATOS UN PRODUCTO SELECCIONADO EN LA PAGINA DE EQUIPO DE CÓMPUTO, EN LA VENTANA MODAL
        if ( objetoDom.matches('#masinfo') ) {
        
            visualiza_equipo(objetoDom);
            
        }

        if ( objetoDom.matches('.opCard') || objetoDom.matches('.clCard')) {
            rotarCard(objetoDom);
        }

        if ( objetoDom.matches('.fto-img-pub')) {
            clickBannerPub(objetoDom);
        }

        if ( objetoDom.matches('.video-frame')) {            
            reproduce_video_youtube(objetoDom);
        }

        if ( objetoDom.matches('.btnFaq') || objetoDom.matches('.accordion-title') || objetoDom.matches('.iconFaq') ) {
            toggleAccordion(objetoDom);            
        }

        // ? BOTÓN PARA ACTIVAR EL POPUP DEL AVISO DE LA TRANSMISION EN VIVO
        if ( objetoDom.matches('#closePop') || objetoDom.matches('#enVivo') ) {
            toggleStriming();
        }

        // ? BOTÓN LATERAL PARA SUBIR AL INICIO DE LA PANTALLA
        if ( objetoDom.matches('#botInicio') ) {

            window.scrollTo({top: 0, behavior: 'smooth'});

            // Este código es con JQUERY
            // $('html, body').animate({
            //    scrollTop: 0
            // }, 100);        
        }

        // ? LINK A PAGINA DEL ROBOT
        if ( objetoDom.matches('.btnrobot')) {
            let url = 'robotmesero.html';
            window.open(url,'_self');
        } 


        // ? LINK A PAGINA DE INICIO
        if ( objetoDom.matches('.mnuInicio') || objetoDom.matches('#logoBDK')) {
            let url = 'index.html';
            window.open(url,'_self');

        }            

        // ? LINK A PAGINA SOFTWARE
        if ( objetoDom.matches('.mnuSoftware')) {
            let url = 'software.html';
            window.open(url,'_self');
        } 

        // ? LINK A PAGINA EQUIPO
        if ( objetoDom.matches('.mnuEquipo')) {
            let url = 'equipocomputo.html';
            window.open(url,'_self');
        } 

        // ? LINK A PAGINAS WEB
        if ( objetoDom.matches('.mnuPagWeb')) {
            let url = 'paginasweb.html';
            window.open(url,'_self');

        }            

        // ? LINK A DISTRIBUIDORES
        if ( objetoDom.matches('.mnuDist')) {
            let url = 'distribuidores.html';
            window.open(url,'_self');
        }            

        // ? LINK A RESEÑAS
        if ( objetoDom.matches('.mnuRese') || objetoDom.matches('#verResenas') ) {
            let url = 'pagresenas.html';
            window.open(url,'_self');
        }

        // ? LINK A GALERÍA
        if ( objetoDom.matches('.mnuGal')) {
            let url = 'galeria.html';
            window.open(url,'_self');
        }            

        // ? LINK A CONTACTO
        if ( objetoDom.matches('.mnuContacto')) {
            let url = 'contacto.html';
            window.open(url,'_self');
        }            

        // ? LINK A SOPORTE
        if ( objetoDom.matches('.mnuSoporte')) {
            let url = 'soporte.html';
            window.open(url,'_self');
        }            

        // ? LINK A BDKREST
        if ( objetoDom.matches('.acc-rest')) {
            let url = 'https://www.bdkrest.com/';
            window.open(url,'_blank');
        }

        // ? LINK A BDKGYM
        if ( objetoDom.matches('.acc-gym')) {
            let url = 'bdkgym.html';
            window.open(url,'_self');
        }             

        // ? LINK A BDKTINT
        if ( objetoDom.matches('.acc-tint')) {
            let url = 'bdktint.html';
            window.open(url,'_self');
        }            

        // ? LINK A BDKSPA
        if ( objetoDom.matches('.acc-spa')) {
            let url = 'bdkspa.html';
            window.open(url,'_self');
        }            

        // ? LINK A BDKBARBER
        if ( objetoDom.matches('.acc-barber')) {
            let url = 'bdkbarber.html';
            window.open(url,'_self');
        }            

        // ? LINK A BDKPOS
        if ( objetoDom.matches('.acc-pos')) {
            let url = 'bdkpos.html';
            window.open(url,'_self');
        }            

        // ? BOTÓN PARA CERRAR LOS POPUP INFORMATIVOS
        if (objetoDom.matches('#close')) {
            const popup = document.querySelector('.infoPopup');
            popup.classList.toggle('active');            
        }

        // ? LINK A WHATSAPP DE VENTAS
        if ( objetoDom.matches('.botWP')) {
            gtag_report_conversion();
            // let url = 'https://api.whatsapp.com/send?phone=528132814080&text=Hola,%20buen%20día,%20%20quiero%20pedir%20informes%20sobre%20';
            let url = 'https://api.whatsapp.com/send?phone=528132814080';
            window.open(url, '_blank');
        } 

        // ? LINK A WHATSAPP DIRECTOR GENERAL
        if ( objetoDom.matches('.botWPDir')) {
            gtag_report_conversion();
            let url = 'https://api.whatsapp.com/send?phone=528128941381';
            window.open(url, '_blank');
        } 

        // ? LINK PARA ENVIAR UN CORREO
        if ( objetoDom.matches('.botMailDir')) {
            gtag_report_conversion();
            let url = 'mailto:direccion@bdksistemas.com.mx';
            window.open(url, '_self');
        } 

        // ? LINKS A FACEBOOK
        if ( objetoDom.matches('.botFB')) {               
            let url = 'https://www.facebook.com/bdksistemas';
            window.open(url, '_blank');
        } 

        // ? LINKS A FACEBOOK PERSONAL
        if ( objetoDom.matches('.botFBDir')) {               
            let url = 'https://www.facebook.com/VentasBDKSistemas/';
            window.open(url, '_blank');
        } 

        // ? LINKS A ISNTAGRAM
        if ( objetoDom.matches('.botInsta')) {
            console.log('Si recibo el click de Insta');                
            let url = 'https://www.instagram.com/bdksistemas';
            window.open(url, '_blank');
        } 

        // ? LINKS A YOUTUBE
        if ( objetoDom.matches('.botYT')) {
            let url = 'https://www.youtube.com/bdksistemas';
            window.open(url, '_blank');
        } 

        // ? LINKS A CLIMAS AUFIT
        if ( objetoDom.matches('.botAufit')) {               
            let url = 'https://www.bdksistemas.com.mx/climas-aufit/';
            window.open(url, '_blank');
        } 

        // ? LINKS A LA PÁGINA DEMO DE BDKREST
        if ( objetoDom.matches('.link-demorest')) {               
            let url = 'https://www.bdkclientes.com/paginasweb/restaurantes/demo1/';
            window.open(url, '_blank');
        } 

        // ? LINKS A LA PÁGINA DEMO DE BDKTINT
        if ( objetoDom.matches('.link-demotint')) {               
            let url = 'https://bdkclientes.com/paginasweb/lavanderias/demo1/';
            window.open(url, '_blank');
        } 

        // ? LINKS A LA PÁGINA DEMO DE BDKGYM
        if ( objetoDom.matches('.link-demogym')) { 
            let url = 'https://bdkclientes.com/paginasweb/gimnasios/demo1/';
            window.open(url, '_blank');
        } 

        // ? LINKS A LA PÁGINA DEMO DE BDKSPA
        if ( objetoDom.matches('.link-demospa')) { 
            let url = 'https://bdkclientes.com/paginasweb/spa/demo1/';
            window.open(url, '_blank');
        } 

        // ? LINKS A LA PÁGINA DEMO DE BDKBARBER
        if ( objetoDom.matches('.link-demobarber')) { 
            let url = 'https://bdkclientes.com/paginasweb/barber/demo1/';
            window.open(url, '_blank');
        } 
        
        // ? LINKS A LA PÁGINA DEMO DE BDKPOS
        if ( objetoDom.matches('.link-demopos')) { 
            let url = 'https://www.bdkclientes.com/paginasweb/otros/demo1/';
            window.open(url, '_blank');
        } 

        // ? BOTÓN REGRESAR
        if ( objetoDom.matches('#regresar')) { 
            window.history.back();
        } 

        // ? ICONO DEL MENÚ HAMBURGUESA
        if ( objetoDom.matches('#botApp')) { 
            
            menuLat = true;
            apertura();
        }

        // ? BOTON PARA MOVER A LA IZQUIERDA UNA IMAGEN DE PAQUETE DE EQUIPO
        if ( objetoDom.matches('.botEqIzq')) { 
            back_promo();
            // --idxEq;
            // if (idxEq < 0) {
            //     idxEq = promociones.length - 1;
            // }
            // visualiza_promo();
            // // console.log(idxEq);            
        }

        // ? BOTON PARA MOVER A LA IZQUIERDA UNA IMAGEN DE PAQUETE DE EQUIPO
        if ( objetoDom.matches('.botEqDer')) { 
            next_promo();
        }


        // ? ENLACES A VÍDEOS DE CAPACITACIÓN
        if ( objetoDom.matches('.enl_capa')) {
            ver_video_capa(objetoDom.id);
        }            

        // // ? LINK AL ARCHIVO DE ERRORES EN LA FACTURACIÓN
        // if ( objetoDom.matches('#errorfac')) {
        //     let url = 'https://drive.google.com/file/d/1-ZSDr4huGYMB0KVuRVp2mwUTG-OTfxKr/view?usp=sharing';
        //     window.open(url, '_blank');
        // }

        // ? CLICK EN LOS ENLACES DE LOS OBJETOS ACORDEON
        if ( objetoDom.matches('.enl_accordeon')) {            
            let pos_obj = document.getElementById('accordion');
            let y = getOffset(pos_obj).top;
            window.scrollTo(0,y);
        }            

        // ? LINK PARA DESCARGAR FICHA TÉCNICA
        if ( objetoDom.matches('.dwn_file')) {
            descarga_ficha(objetoDom.id);
        }            

        // ? LINK PARA ENTRAR A LAS PÁGINAS DE DESCARGA DEL DEMO
        if ( objetoDom.matches('.link_demos')) {
            paginas_demo(objetoDom.id);
        }            

        // ? LINK PARA COMPRAR SOFTWARE
        if ( objetoDom.matches('.link_pago')) {
            pagina_pago(objetoDom.id);
        }

        // ? LINK PARA REGRESAR A UNA RESEÑA ANTERIOR
        if ( objetoDom.matches('.actTestimonios')) {

            obtenTestimonios();
            
        }

        // ? CLICK EN LOS ENLACES PARA VER LOS VIDEOS INFORMATIVOS
        if ( objetoDom.matches('.urlVideo')) {

            reproduce_video(objetoDom);    
            
        }            

    } 


    // ! FUNCIÓN PARA MEDIR LAS CONVERSIONES Y CLICS EN LOS BOTONES DE CONTACTO - CÓDIGO DE GOOGLE ADS
    function gtag_report_conversion(url) {
        var callback = function () {
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-1011948451/dVALCJDYqr4DEKO3xOID',
            'event_callback': callback
        });
        return false;
    }

    // ! FUNCIÓN PARA ABRIR EL MENÚ CUANDO EL TAMAÑO SEA TABLET O MÓVIL
    function apertura() {
        let menu    = document.getElementById('navPrincipal');
        let botSubir = document.getElementById('botInicio');
        let botAccWP	 = document.getElementById('botWP');
        
        if (cerrado) {
            menu.style.width = '100vw!important';
            cerrado = false;
            
            if (botSubir) {
                botSubir.style.visibility = 'hidden';
                botAccWP.style.visibility = 'hidden';
            }

        } else {
            menu.style.width = '0%';
            menu.style.overflow = 'hidden';
            cerrado = true;
            menuLat = false;
            if (botSubir) {
                botSubir.style.visibility = 'visible';
                botAccWP.style.visibility = 'visible';
            }
        }
        menu.classList.toggle('menu2');
    };   


    // ! FUNCIÓN PARA ACTIVAR EL EFECTO DE LAS PREGUNTAS FRECUENTES
    function toggleAccordion(elem) {

        let elemFaq;
        let i;

        if (elem.matches('.btnFaq') === false) {
            elemFaq = elem.parentNode;
        } else {
            elemFaq = elem;
        }

        const items = document.querySelectorAll(".accordion button");
        const itemToggle = elemFaq.getAttribute('aria-expanded');
        
        for (i = 0; i < items.length; i++) {
          items[i].setAttribute('aria-expanded', 'false');
        }
        
        if (itemToggle == 'false') {
          elemFaq.setAttribute('aria-expanded', 'true');
        }
    }
    
    function navBackground() {

        let nav = document.querySelector('.nav');
        let desp_actual = window.pageYOffset;
        let ancho_screen = window.innerWidth;        

        if ( desp_actual <= 300 ) {

            nav.style.borderBottom = ''; 

            if (ancho_screen > 768 ) {
                nav.style.background = 'rgba(0,0,0,0.5)';
            } else {
                nav.style.background = 'linear-gradient(0deg, rgba(0,0,0,0) 5%, rgba(0,0,0,1) 100%)';
            }

        } else {
            nav.style.background = '#1F2022';
            nav.style.borderBottom = '2px solid #cb001a';
        }
        nav.style.transition = '.5s';
    }

    function next_fondo() {
        let idxHero = actBanner(heros, 0);      
        nextBanner(heros, idxHero, '+');        
    }

    function next_promo() {      
        let idxPromo = actBanner(promociones, 0);      
        nextBanner(promociones, idxPromo, '+');        
    }

    function back_promo() {      
        let idxPromo = actBanner(promociones, 0);      
        nextBanner(promociones, idxPromo, '-');
    }

    function actBanner(array, idxBan) {
        while (idxBan < array.length) {
            if (array[idxBan].classList.contains('banner_visible')) {
                break;
            }
            idxBan++;
        }
        return idxBan;
    }

    function nextBanner(array, idx, opr) {
        let idxNext = idx;
        if (opr==='+') {
            idxNext++;
            if (idxNext > array.length - 1) {
                idxNext = 0;
            }    
        } else {
            --idxNext;
            if (idxNext < 0) {
                idxNext = array.length - 1;
            }
        }
        array[idx].classList.remove('banner_visible');
        array[idxNext].classList.add('banner_visible');
    }

    //! ==================================================================
    //* Función para poder visualizar en pantalla uno de los vídeos de capacitación
    //* Recive como parámetro el ID de un vídeo y lo busca en el archivo videos_capacutacion.json
    //* Obtiene el URL y lo muestra en pantalla
    //! ==================================================================

    function ver_video_capa(id_video) {

        let frame_video = document.getElementById('video_encurso');

        getSrcById(id_video,1)
        .then((src) => {
            if (src) {

                frame_video.src = src;
                let y = getOffset(frame_video).top;            
                window.scrollTo(0,y);
        
            } else {
                console.log('Video not found');
            }
        })
            .catch((error) => {
            console.error('Error:', error.message);
        });    
    }
      
    //! ==================================================================
    //* Función que recibe un ID y lo buca en el archivo filestodwn.json
    //* El encontrar el ID regresa el link que tiene que descargar o al que se tiene que dirigir
    //! ==================================================================

    function descarga_ficha(id_file) {

        getSrcById(id_file,2)
        .then((link) => {
            if (link) {

                window.open(link, '_blank');
                
            } else {
                console.log('File not found : Clicks.js : 731');
            }
        })
            .catch((error) => {
            console.error('Error:', error.message);
        });    
    }

    //! ==================================================================
    //* Función que recibe un ID para obtener la página que tiene que abrir
    //* Esta función se ejecuta de los botones DESCARGAR DEMO de los sistemas
    //* Si encuentra el ID regresa el valor del campo SRC
    //! ==================================================================

    function paginas_demo(id_page) {

        getSrcById(id_page,3)
        .then((link) => {
            if (link) {

                window.open(link, '_self');
                
            } else {
                console.log('File not found : Clicks.js : 731');
            }
        })
            .catch((error) => {
            console.error('Error:', error.message);
        });    

    }

    // FUNCION PARA IR AL ENLACE DE MERCADO PAGO
    function pagina_pago(id_link) {

        getSrcById(id_link,4)
        .then((link) => {
            if (link) {

                window.open(link, '_blank');
                
            } else {
                console.log('File not found : Clicks.js : 726');
            }
        })
            .catch((error) => {
            console.error('Error:', error.message);
        });    

    }

    //! ==================================================================
    //* Función que recibe un ID y el tipo de ID
    //* Dependiendo del TIPO, busca el ID en algún determinado archivo JSON
    //* Si encuentra el ID regresa el valor del campo SRC
    //! ==================================================================

    async function getSrcById(id,tipo) {
        try {

            let filejson;

            switch (tipo) {
                case 1:
                    filejson = "videos_capacitacion.json";
                    break;
                case 2:
                    filejson = "filestodwn.json";
                    break;
                case 3:
                    filejson = "linksdemos.json";
                    break;
                case 4:
                    filejson = "linkspagos.json";
                    break;    
                default:
                    filejson = "default.json";
                    break;
            }
            
            let pathfile = "../../assets/" + filejson;

            const response = await fetch(pathfile);
            const listado = await response.json();
          
            const registro = listado.find((registro) => registro.id === id);
            if (registro) {
                return registro.src;
            } else {
                throw new Error('ID not found : ' + pathfile + ' L: 717');
            }
        } catch (error) {
            console.error('Error:', error.message);
            return null;
        }
    }

    // FUNCION PARA OBTENER LA POSICIÓN EN VERTICAL DENTRO DE LA PÁGINA DE UN ELEMENTO
    function getOffset( el ) {
        var _x = 0;
        var _y = 0;
        while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
              _x += el.offsetLeft - el.scrollLeft;
              _y += el.offsetTop - el.scrollTop;
              el = el.offsetParent;
        }
        return { top: _y, left: _x };
    }

    function visualiza_equipo( obj ) {

        // let clave_equipo = obj.idprod;
        let clave_equipo = obj.getAttribute('idprod');

        // Cargando la lista de productos

        fetch('../../assets/equipocomputo.json')
        .then(response => response.json())
        .then(data => {

            let equipoComputoJSON = data;
      
            // A partir de aquí, puedes acceder y manipular la información del archivo JSON
            // según tus necesidades.

            let productoEncontrado = equipoComputoJSON.productos.find(function(producto) {
                return producto.clave === clave_equipo;
            });
          
            let card_eq_nombre = document.getElementById('card_eq_nombre')

            if (productoEncontrado) {

                card_eq_nombre.textContent = productoEncontrado.nombre;

                let card_eq_imagen = document.getElementById('card_eq_imagen');
                let card_eq_desc = document.getElementById('card_eq_desc');
                let card_eq_promo = document.getElementById('card_eq_promo');
                let card_eq_precio = document.getElementById('card_eq_precio');

                card_eq_imagen.src = productoEncontrado.foto
                card_eq_imagen.alt = productoEncontrado.nombre
                card_eq_desc.innerHTML = '<i class="fas fa-check-circle"></i>' + ' ' + productoEncontrado.descripcion;
                card_eq_promo.innerHTML = '<i class="fas fa-check-circle"></i>' + ' ' + productoEncontrado.promocion;
                card_eq_precio.textContent = '$ ' + productoEncontrado.precio;                

            } else {
                card_eq_nombre.textContent = 'Producto no identificado';
                console.log("No se encontró un producto con la clave '" + clave_equipo + "'");
            }
          
        })
        .catch(error => {
            console.log('Error al cargar el archivo JSON:', error);
        });
              
    }

    // ! FUNCIÓN PARA REPRODUCIR UN VÍDEO 

    function reproduce_video( obj ) {

        // console.log(obj);
                
        let idvid = $(obj).attr('idvid');
        let tipvid = $(obj).attr('tipo');
        
        let nomFile = tipvid === "reseña" ? "video_testimonios" : "videos_pagina";
        let fileJSON = '../../assets/' + nomFile + '.json';
                    
        fetch(fileJSON)
            .then(response => response.json())
            .then(data => {
        
                // Buscar el registro con tipo="pagina" y clave="01"
                let video = data.videos.find(function(item) {
                    return item.idvid === idvid;
                });
                            
                const $modTitulo = $('#mod-titulo');
                const $modDesc = $('#mod-desc');
        
                $modTitulo.text(video.titulo);
                $modDesc.text(video.descrip);

                if (tipvid !== "reseña") {
                    let vportada = document.getElementById('portada_video');

                    console.log(vportada);
                    
                    if (vportada !== undefined && vportada !== null) {
                        vportada.innerHTML = `
                            <picture>
                                <source srcset="build/img/vportadas/${video.idvid}.avif" type="image/avif">
                                <source srcset="build/img/vportadas/${video.idvid}.webp" type="image/webp">
                                <img width="350" height="150" src="build/img/vportadas/${video.idvid}.jpg" alt="Portada del vídeo en pantalla">
                            </picture>

                            <a href="#myModal" class="play urlVideo"><img width="100" height="100" src="build/img/play.png" alt="Dale PLAY al vídeo" class="urlVideo" idvid="${video.idvid}" tipo="${video.tipo}"></a>
                        `;
                    }
                }

                $iframeVideo.attr('src',video.link+"?autoplay=1");
                $vtnaVideo.modal('show');
            
            })
        .catch(error => {
            console.log('Error al cargar el archivo JSON:', error);
        });

    }

    // function obtenTestimonios() {

    //     const conjunto_testimonios = document.getElementById('conjunto_testimonios');

    //     if (conjunto_testimonios !== null) {

    //         conjunto_testimonios.innerHTML = '';

    //         fetch('../../assets/video_testimonios.json')
    //         .then(response => response.json())
    //         .then(data => {

    //             const testimonios = data.videos;
    //             let randomIndex = 0;
    //             let testimonioAleatorio = {};

    //             for (let i = 0; i < 6; i++) {

    //                 randomIndex = Math.floor(Math.random() * testimonios.length);
    //                 testimonioAleatorio = testimonios[randomIndex];

    //                 const portada_testimonio = document.createElement('div');
    //                 portada_testimonio.classList.add('portada-testimonio');
                    
    //                 portada_testimonio.innerHTML = `
    //                     <picture>
    //                         <source srcset="build/img/vtestimonios/${testimonioAleatorio.idvid}.avif" type="image/avif">
    //                         <source srcset="build/img/vtestimonios/${testimonioAleatorio.idvid}.webp" type="image/webp">
    //                         <img width="350" height="150" src="build/img/vtestimonios/${testimonioAleatorio.idvid}.jpg" alt="Portada del vídeo en pantalla">
    //                     </picture>

    //                     <a href="#myModal" class="play urlVideo"><img width="100" height="100" src="build/img/play.png" alt="Dale PLAY al vídeo" class="urlVideo" idvid="${testimonioAleatorio.idvid}" tipo="${testimonioAleatorio.tipo}"></a>
    //                 `;

    //                 conjunto_testimonios.appendChild(portada_testimonio);                       

    //             }

    //         })
    //         .catch(error => {
    //             console.log('Error al cargar el archivo JSON:', error);
    //         });    
    //     }
    // }    

    function obtenTestimonios() {

        const conjunto_testimonios = document.getElementById('conjunto_testimonios');

        if (conjunto_testimonios !== null) {

            conjunto_testimonios.innerHTML = '';

            fetch('../../assets/video_testimonios.json')
                .then(response => response.json())
                .then(data => {
                    const testimonios = data.videos;
                    const testimoniosElegidos = new Set(); // Utilizamos un conjunto para almacenar los testimonios elegidos
                    let testimonioAleatorio = {};

                    while (testimoniosElegidos.size < 6) {
                        const randomIndex = Math.floor(Math.random() * testimonios.length);
                        testimonioAleatorio = testimonios[randomIndex];

                        if (!testimoniosElegidos.has(testimonioAleatorio)) { // Verificamos si el testimonio ya ha sido elegido
                            testimoniosElegidos.add(testimonioAleatorio); // Agregamos el testimonio al conjunto de testimonios elegidos

                            const portada_testimonio = document.createElement('div');
                            portada_testimonio.classList.add('portada-testimonio');

                            portada_testimonio.innerHTML = `
                                <picture>
                                <source srcset="build/img/vtestimonios/${testimonioAleatorio.idvid}.avif" type="image/avif">
                                <source srcset="build/img/vtestimonios/${testimonioAleatorio.idvid}.webp" type="image/webp">
                                <img width="350" height="150" src="build/img/vtestimonios/${testimonioAleatorio.idvid}.jpg" alt="Portada del vídeo en pantalla">
                                </picture>
                
                                <a href="#myModal" class="play urlVideo"><img width="100" height="100" src="build/img/play.png" alt="Dale PLAY al vídeo" class="urlVideo" idvid="${testimonioAleatorio.idvid}" tipo="${testimonioAleatorio.tipo}"></a>
                            `;

                            conjunto_testimonios.appendChild(portada_testimonio);
                        }
                    }
                })
            .catch(error => {
                console.log('Error al cargar el archivo JSON:', error);
            });
        }
    }
      

    // ! En esta función se muestran en pantalla todos los testimonios que
    // ! se encuentren en el archivo de las reseñas de vídeo

    function video_testimonios() {

        const conjunto_testimonios = document.getElementById('cntTestimonios');

        if (conjunto_testimonios !== null) {

            conjunto_testimonios.innerHTML = '';

            fetch('../../assets/video_testimonios.json')
            .then(response => response.json())
            .then(data => {

                const testimonios = data.videos;

                testimonios.forEach(testimonio => {

                    const portada_testimonio = document.createElement('div');
                    portada_testimonio.classList.add('portada-testimonio');
                    
                    portada_testimonio.innerHTML = `
                        <picture>
                            <source srcset="build/img/vtestimonios/${testimonio.idvid}.avif" type="image/avif">
                            <source srcset="build/img/vtestimonios/${testimonio.idvid}.webp" type="image/webp">
                            <img width="350" height="150" src="build/img/vtestimonios/${testimonio.idvid}.jpg" alt="Portada del vídeo en pantalla">
                        </picture>

                        <a href="#myModal" class="play urlVideo"><img width="100" height="100" src="build/img/play.png" alt="Dale PLAY al vídeo" class="urlVideo" idvid="${testimonio.idvid}" tipo="${testimonio.tipo}"></a>
                    `;

                    conjunto_testimonios.appendChild(portada_testimonio);                       

                })

            })
            .catch(error => {
                console.log('Error al cargar el archivo JSON:', error);
            });
    
        }

    }
    
    function video_robot(obj) {

       const parentPadre = obj.parentNode.parentNode.parentNode;
       const iframeElement = parentPadre.querySelector(".youtube-video iframe");
       iframeElement.setAttribute("src", null );  // Autoplay y mute

       const parentElement = obj.parentNode.parentNode;
       parentElement.remove();
        
       iframeElement.setAttribute("src", "https://www.youtube.com/embed/"+obj.dataset.embed+"?autoplay=1" );  // Autoplay y mute
        
    }

    //! =================================================================================
    //* Función para llenar el slider de los banners de publicidad
    //! =================================================================================

    function llena_bannerspub(div_pub) { 

        let divIndicadores = document.getElementById('div_indicadores');

        div_pub.innerHTML = '';
        divIndicadores.innerHTML = '';

        fetch('../../assets/banners_pub.json')
            .then(response => response.json())
            .then(data => {
                                    
                const anuncios = data.anuncios;
                let i = 0;

                anuncios.forEach(anuncio => {

                    // ! Creando los botones de los indicadores del carrusel
                    let button = document.createElement("button");
                    button.setAttribute("type", "button");
                    button.setAttribute("data-bs-target", "#crs_pub");
                    button.setAttribute("data-bs-slide-to", i.toString());                    

                    const link_anuncio = document.createElement('div');

                    link_anuncio.classList.add('carousel-item');
                    if (i===0) {
                        button.classList.add("active");                            
                        link_anuncio.classList.add('active');
                    }
                    i++;

                    link_anuncio.innerHTML = `

                        <a href="${anuncio.href}">
                            <picture>
                                <source srcset="build/img/banners/${anuncio.clave}.avif" type="image/avif">
                                <source srcset="build/img/banners/${anuncio.clave}.webp" type="image/webp">
                                <img src="build/img/banners/${anuncio.clave}.jpg" alt="Banner publicitario" class="d-block fto-img-pub" style="width:100%">
                            </picture>    
                        </a>

                    `;

                    // Agrega la anuncio al contenedor 
                    divIndicadores.appendChild(button);                       
                    div_pub.appendChild(link_anuncio);
                });
            })
        .catch(error => {
            console.error('Error al obtener los datos del archivo JSON:', error);
        });                      
    
    }

    //! =================================================================================
    //* Abre el enlace de uno de los banners de publicidad
    //! =================================================================================

    function clickBannerPub(obj) {
        
        const hrefValue = obj.parentNode.parentNode.getAttribute('href');
        window.open(hrefValue, '_blank');

    }

    //! =================================================================================
    //* Función para reproducir vídeos de Youtube directamente en la página
    //! =================================================================================

    function reproduce_video_youtube(obj) {
        
        const parentPadre = obj.parentNode.parentNode.parentNode;
        const iframeElement = parentPadre.querySelector(".youtube-video iframe");
        iframeElement.setAttribute("src", null );  // Autoplay y mute
 
        const parentElement = obj.parentNode.parentNode;
        parentElement.remove();
         
        iframeElement.setAttribute("src", "https://www.youtube.com/embed/"+obj.dataset.embed+"?autoplay=1" );  // Autoplay y mute
         
     }


})();